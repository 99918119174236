<template>
<div style="width: 70%;">
     <el-table
        :data="tableData"
    style="width: 100%">
    <el-table-column
      label="用户名"
      prop="username">
    </el-table-column>

      <el-table-column
      label="手机号"
      prop="tel">
    </el-table-column>
      <el-table-column
      style="color:red"
      label="权限"
      prop="uid">
    </el-table-column>
    <el-table-column
      label="账号状态"
      prop="isdelete">
    </el-table-column>

    <el-table-column
      align="right">
      <template slot-scope="scope">
  <el-button
            v-show="manager(scope.row)"
          size="mini"
          type="success"

          @click="MsetManager(scope.$index, scope.row)">管理</el-button>
           <el-button
               v-show="!manager(scope.row)"
          size="mini"
          type="info"

          @click="MunManger(scope.$index, scope.row)">取消</el-button>
        <el-button v-show="banUser(scope.row)"
          size="mini"
          type="danger"
          class="left"
          @click="MbanUser(scope.$index, scope.row)">封禁</el-button>

        <el-button
          v-show="!banUser(scope.row)"
          size="mini"
           type="warning"
           class="left"
          @click="MunDo(scope.$index, scope.row)">解封</el-button>

      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import { MgetAllUsers, MbanUser, MunDo, MsetManager, MunManger } from '../../API/manager.js'
export default {
  data () {
    return {
      tableData: [],

      isDelete: true
    }
  },
  methods: {
    // 显示封禁按钮
    banUser (row) {
      if (row.isdelete === '✔') {
        return true
      } else {
        return false
      }
    },
    manager (row) {
      if (row.uid === '管理员') {
        return false
      } else {
        return true
      }
    },
    // 获取用户信息
    async getUsers () {
      const { data: res } = await MgetAllUsers()
      if (res.status === 0) {
        this.tableData = res.results
        this.tableData.map((item, index, arr) => {
          if (item.uid === 0) {
            item.uid = '管理员'
          } else {
            item.uid = '用户'
          }
          if (item.isdelete === 0) {
            item.isdelete = '✔'
          } else {
            item.isdelete = '封号'
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
    },
    // 封号
    async MbanUser (index, row) {
      const { data: res } = await MbanUser({ id: row.id })
      if (res.status === 0) {
        this.tableData.map((item) => {
          if (item.id === row.id) {
            item.isdelete = '封号'
            this.$message({
              showClose: true,
              type: 'success',
              message: res.message
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
      this.isDelete = false
    },
    // 解封
    async MunDo (index, row) {
      const { data: res } = await MunDo({ id: row.id })
      if (res.status === 0) {
        this.tableData.map((item) => {
          if (item.id === row.id) {
            item.isdelete = '✔'
            this.$message({
              showClose: true,
              type: 'success',
              message: res.message
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
    },
    // 设置管理员
    async MsetManager (index, row) {
      const { data: res } = await MsetManager({ id: row.id })
      console.log(row)
      if (res.status === 0) {
        this.tableData.map((item) => {
          if (item.id === row.id) {
            item.uid = '管理员'
            this.$message({
              showClose: true,
              type: 'success',
              message: res.message
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
      this.isDelete = false
    },
    // 取消管理员
    async MunManger (index, row) {
      console.log(row)
      const { data: res } = await MunManger({ id: row.id })
      console.log(res)
      if (res.status === 0) {
        this.tableData.map((item) => {
          if (item.id === row.id) {
            item.uid = '用户'
            this.$message({
              showClose: true,
              type: 'success',
              message: res.message
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
    }
  },
  created () {
    this.getUsers()
  }

}
</script>

<style lang="less" scoped>

// /deep/ .has-gutter tr td{
//     text-align: center;

//  }

//     /deep/  .el-button+.el-button {
//       margin-left: 0 ;
//     }
//     .left{
//         margin-left: 10px !important;
//     }
/deep/ .has-gutter tr td{
    text-align: center;

 }

    /deep/  .el-button+.el-button {
      margin-left: 0 !important;
    }
</style>
